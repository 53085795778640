<template>
  <div>
    <div class="dis jus_content">
      <div class="flex align_center">
        <div class="icon"></div>
        <div class="service">企业子账号管理</div>
      </div>
      <div
        style=" width: 198px; height: 40px; background: #ff6618; border-radius: 9px; color: #fff; line-height: 40px; text-align: center;
        "
        @click="addcount()"
      >
        添加企业子账号
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="user_id" label="ID" min-width="100" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="电话" min-width="180" align="center">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="添加时间"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="props">
          <!-- <el-button type="text">编辑</el-button> -->
          <el-button type="text" @click="del(props.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="添加企业子账号"
      :visible.sync="setcountlogin"
      width="600px"
      center
    >
      <div>
        <el-form ref="form" :model="setcount" label-width="80px">
          <el-form-item label="电话" required>
            <el-input v-model="setcount.phone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="flex mat_30">
        <div
          style="
            width: 137px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: #ff6618;
            border-radius: 10px;
            color: #fff;
          "
          @click="countadd()"
        >
          确认添加
        </div>
        <div
          class="marl_30"
          style="
            width: 137px;
            height: 50px;
            line-height: 50px;
            text-align: center;
           
            border-radius: 10px;
            background: #cfcfcf;
            color: #fff;
          "
          @click="setcountlogin = false"
        >
          取消
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      setcount: { phone: "" },
      setcountlogin: false,
      tableData: [],
    };
  },
  created() {
    this.getcountorder();
  },
  methods: {
    del(item){
      console.log(item)
      let data = {
        token: localStorage.eleToken,
        id: item.user_id
      };
      this.$post("user_child_del", data).then((res) => {
        console.log(res, 'res')
        this.$message({
              type: "success",
              message: res.msg,
            });
            this.getcountorder()
      });
    },
    addcount() {
      this.authentication();
    },

    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      console.log(data, "data");
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res456");
        if (res.status === 1) {
          this.setcountlogin = true;
        } else if (res.status === 2) {
          // this.$message({
          //   type: "warning",
          //   message: "请先认领",
          // });
          setInterval(() => {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            location.href = "https://sso.smeqh.cn/realname/index";
          }, 2000);
        }
      });
    },

    getcountorder() {
      let data = {
        token: localStorage.eleToken,
      };

      this.$post("user_child_lst ", data).then((res) => {
        console.log(res, "res");
        this.tableData = res.result;
      });
    },

    countadd() {
      let data = {
        token: localStorage.eleToken,
        phone: this.setcount.phone,
      };

      this.$post("user_child_add ", data).then((res) => {
        console.log(res, "res");
        this.setcountlogin = false;
        this.getcountorder()
      });
    },
   

    

    
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}
</style>
